import { Injectable, Inject } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { LoggerService } from '@service/loggers/logger.service';
import { environment } from 'src/environments/environment';

/**
 * https://medium.com/@tiagovalverde/how-to-save-your-app-state-in-localstorage-with-angular-ce3f49362e31
 */

// key that is used to access the data in local storage
const ACCESS_TOKEN_KEY = (environment.mockBackend?'mock_':'')+'tun_accesstoken_temp3';
const REFRESH_TOKEN_KEY = (environment.mockBackend?'mock_':'')+'tun_refreshtoken_temp3';
const ZONE_ID_KEY = (environment.mockBackend?'mock_':'')+'tun_zoneId_temp3';

const ORANGE_TAB_OPEN = (environment.mockBackend?'mock_':'')+"tun_orange_tab_open";

const CREDENTIAL_CONVERSION_DONE = (environment.mockBackend?'mock_':'')+"tun_cred_conv_done";
const ZONE_CONNECTIONS = (environment.mockBackend?'mock_':'')+"tun_zone_connections";
const ACTIVE_ZONE_CONNECTION_ZONE_ID = (environment.mockBackend?'mock_':'')+"tun_active_zone_connection";
const LAST_ACTIVE_ZONE_CONNECTION_ZONE_ID = (environment.mockBackend?'mock_':'')+"tun_last_active_zone_connection";

const LAST_USED_VERSION = (environment.mockBackend?'mock_':'')+"tun_last_used_version";
const LAST_USED_INTERFACE_MAJOR_VERSION = (environment.mockBackend?'mock_':'')+"tun_last_used_major_version";

const ONSCREEN_KEYBOARD_ACTIVE = (environment.mockBackend?'mock_':'')+"tun_onscreen_keyboard";

const FORCED_DEVICE = (environment.mockBackend?'mock_':'')+'tun_forced_device';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private LOGGER_CLASSNAME = LocalStorageService.name;

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService,
              private logger: LoggerService) { }

  get accessToken() {
    return this.storage.get(ACCESS_TOKEN_KEY);
  }
  set accessToken(value : string){
    this.storage.set(ACCESS_TOKEN_KEY, value);
    this.logger.info(this.LOGGER_CLASSNAME, 'accessToken', `save accesstoken (${value}) to local storage`);
  }

  get refreshToken() {
    return this.storage.get(REFRESH_TOKEN_KEY);
  }
  set refreshToken(value : string){
    this.storage.set(REFRESH_TOKEN_KEY, value);
    this.logger.info(this.LOGGER_CLASSNAME, 'refreshToken', `save refreshToken (${value}) to local storage`);
  }




  get zoneId() {
    return this.storage.get(ZONE_ID_KEY);
  }
  set zoneId(value : number){
    this.storage.set(ZONE_ID_KEY, value);
    this.logger.info(this.LOGGER_CLASSNAME, 'zoneId', `save zoneId (${value}) to local storage`);
  }

  public clearTokens() {
    this.accessToken = null;
    this.refreshToken = null;
  }


  get orangeTabOpen(): boolean{
    return this.storage.get(ORANGE_TAB_OPEN);
  }
  set orangeTabOpen(value : boolean){
    this.storage.set(ORANGE_TAB_OPEN, value);
  }


  /**
   * Zone connection settings
   */

  get credentialConversionDone(): boolean{
    if (this.storage.has(CREDENTIAL_CONVERSION_DONE)){
      return this.storage.get(CREDENTIAL_CONVERSION_DONE);
    }
    return false;
  }
  set credentialConversionDone(value : boolean){
    this.storage.set(CREDENTIAL_CONVERSION_DONE, value);
  }

  get zoneConnections(): string{
    if (this.storage.has(ZONE_CONNECTIONS)){
      return this.storage.get(ZONE_CONNECTIONS);
    }
    return undefined;
  }
  set zoneConnections(value : string){
    this.storage.set(ZONE_CONNECTIONS, value);
  }


  //current active zone connection id
  get activeZoneConnectionZoneId(): number{
    if (this.storage.has(ACTIVE_ZONE_CONNECTION_ZONE_ID)){
      return this.storage.get(ACTIVE_ZONE_CONNECTION_ZONE_ID);
    }
    return undefined;
  }
  set activeZoneConnectionZoneId(value : number){
    if (value == null ){
      this.storage.remove(ACTIVE_ZONE_CONNECTION_ZONE_ID);
    }else{
      this.storage.set(ACTIVE_ZONE_CONNECTION_ZONE_ID, value);
    }

  }

  //last active zone connection id
  get lastActiveZoneConnectionZoneId(): number{
    if (this.storage.has(LAST_ACTIVE_ZONE_CONNECTION_ZONE_ID)){
      return this.storage.get(LAST_ACTIVE_ZONE_CONNECTION_ZONE_ID);
    }
    return undefined;
  }
  set lastActiveZoneConnectionZoneId(value : number){
    if (value == null ){
      this.storage.remove(LAST_ACTIVE_ZONE_CONNECTION_ZONE_ID);
    }else{
      this.storage.set(LAST_ACTIVE_ZONE_CONNECTION_ZONE_ID, value);
    }
  }

  //last used version
  get lastUsedVersion(): number{
    if (this.storage.has(LAST_USED_VERSION)){
      return this.storage.get(LAST_USED_VERSION);
    }
    return undefined;
  }
  set lastUsedVersion(value : number){
    if (value == null ){
      this.storage.remove(LAST_USED_VERSION);
    }else{
      this.storage.set(LAST_USED_VERSION, value);
    }
  }

  //last used interface major version ("V4" or "V5")
  get lastUsedInterfaceMajorVersion(): string{
    if (this.storage.has(LAST_USED_INTERFACE_MAJOR_VERSION)){
      return this.storage.get(LAST_USED_INTERFACE_MAJOR_VERSION);
    }
    return undefined;
  }
  set lastUsedInterfaceMajorVersion(value : string){
    if (value == null ){
      this.storage.remove(LAST_USED_INTERFACE_MAJOR_VERSION);
    }else{
      this.storage.set(LAST_USED_INTERFACE_MAJOR_VERSION, value);
    }
  }




  get onscreenKeyboardActive(): boolean{
    if (this.storage.has(ONSCREEN_KEYBOARD_ACTIVE)){
      return this.storage.get(ONSCREEN_KEYBOARD_ACTIVE);
    }
    return false;
  }
  set onscreenKeyboardActive(value : boolean){
    this.storage.set(ONSCREEN_KEYBOARD_ACTIVE, value);
  }



  //forced device
  get forcedDevice(): string{
    if (this.storage.has(FORCED_DEVICE)){
      return this.storage.get(FORCED_DEVICE);
    }
    return undefined;
  }
  set forcedDevice(value : string){
    if (value == null ){
      this.storage.remove(FORCED_DEVICE);
    }else{
      this.storage.set(FORCED_DEVICE, value);
    }
  }


}
