import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject, merge } from 'rxjs';
import { checkIfCommServerRunning, createBroadcastService } from "@todesktop/client-comm-server";
import { LoggerService } from '@service/loggers/logger.service';
import { isDesktopApp } from '@todesktop/client-core/platform/todesktop';
import { ZoneConnectionsService } from '../authentication/zone-connections.service';
import { finalize, takeUntil } from 'rxjs/operators';
import { DTO_ZoneCode, ZoneCodeApiService } from '@service/api/zone-code-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ZoneConnection } from '@model/zoneConnection';
import customProtocolCheck from 'custom-protocol-check';
import { environment } from 'src/environments/environment';


/**
 * This service is used to share zone connections to the desktop app.
 */

@Injectable({
  providedIn: 'root'
})
export class ShareZoneConnectionToOtherAppService implements OnInit, OnDestroy {

  private LOGGER_CLASSNAME = ShareZoneConnectionToOtherAppService.name;

  public bootService(){
    console.warn("ShareZoneConnectionToOtherAppService.bootService");
  }

  /*
  private ports = [22445, 39214];
  private testCommServerFunctions(){
    type Req = { url: string };
    type Res = string;

    const { broadcast, handleBroadcast } = createBroadcastService<Req, Res>(this.ports);

    if (!isDesktopApp()) {
      console.log("[context]: web app");

      checkIfCommServerRunning(this.ports).then(async (isRunning) => {
        console.log({ isRunning });

        if (isRunning) {
          const res = await broadcast({ url: "http://localhost:5173/app" });
          if (res && res.success) {
            console.log(res.data);
          }
        }
      });
    } else {
      console.log("[context]: desktop app");

      const unsubscribe = handleBroadcast(({ url }) => {
        console.log(`redirecting to ${url}`);
        //... redirect to url
        return `redirected to ${url}`;
      });

      window.addEventListener("unload", () => unsubscribe());

      const broadcastService = createBroadcastService(this.ports);

    // Register a listener for incoming messages
    broadcastService.handleBroadcast((message) => {
      console.warn('Received message:', message);
    });

    if (isDesktopApp()){
      checkIfCommServerRunning(this.ports).then(async (isRunning) => {
        console.warn("ShareDesktopAppService isrunning: " + isRunning);
        if (isRunning) {
          // Send a message
          broadcastService.broadcast({ foo: "askToShareZones" });
        }
      });
    }else{
      checkIfCommServerRunning(this.ports).then(async (isRunning) => {
        console.warn("ShareDesktopAppService isrunning: " + isRunning);
        if (isRunning) {
          // Send a message
          broadcastService.broadcast({ foo: "readyToShareZones" });
        }
      });
    }

  }
      */

  constructor(
    private loggerService: LoggerService,
    private zoneConnectionsService: ZoneConnectionsService,
    private zoneCodeApiService: ZoneCodeApiService
  ) {


    }


  ngOnInit(): void {
    /*
      checkIfCommServerRunning(this.ports).then(async (isRunning) => {
        console.log(isRunning);
      });
    */

      this.zoneConnectionsService.activeZoneConnection$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(
        ()=>{
          this.activeZoneChanged$.next();
          this.unloadZoneCode();
        }
      )
  }


  private destroy$ = new Subject<void>();
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Open current zone in desktop app
   */

  public openZoneInDesktopApp(){
    if (this.zoneCode){
      this.startDesktopApp();
    }else{
      this.watchForDesktopApp = true;
      this.watchZoneCode = true;
      if (!this.loading){
        this.loadZoneCode();
      }
    }
  }

  /**
   * Open current zone in other version
   */
  public openZoneInOtherVersion(){
    if (this.zoneCode){
      this.startOtherVersion();
    }else{
      this.watchForOtherVersion = true;
      this.watchZoneCode = true;
      if (!this.loading){
        this.loadZoneCode();
      }
    }
  }

  //Watch zone code
  private _watchZoneCode = false;
  private watchForDesktopApp = false;
  private watchForOtherVersion = false;
  private stopWatchingZoneCode = new Subject<void>();
  private set watchZoneCode(value: boolean){
    if (this._watchZoneCode != value){
      this._watchZoneCode = value;
      this.stopWatchingZoneCode.next();
      if (this.watchZoneCode){
        this.zoneCode$
        .pipe(
          takeUntil(
            this.stopWatchingZoneCode
          )
        )
        .subscribe(
          (zoneCode)=>{
            if (zoneCode){
              this.watchZoneCode = false;
              if (this.watchForDesktopApp){
                this.watchForDesktopApp = false;
                this.startDesktopApp();
              }else if (this.watchForOtherVersion){
                this.watchForOtherVersion = false;
                this.startOtherVersion();
              }
            }
          }
        );
      }
    }
  }
  private get watchZoneCode(){
    return this._watchZoneCode;
  }


public playerNotFound = false;
  private startDesktopApp(){
    this.playerNotFound = false;
    const appToOpen = environment.desktopProtocol + "?zoneId=" + this.zoneConnectionsService.activeZoneConnection.externalZoneId + "&zoneCode=" + this.zoneCode
    customProtocolCheck(
      appToOpen,
      () => {
        this.playerNotFound = true;
      },
      () => {
        console.log("Custom protocol found and opened the file successfully.");
      }
    );
  }

  private startOtherVersion(){
    const url = environment.otherVersionUrl + "?zoneId=" + this.zoneConnectionsService.activeZoneConnection.externalZoneId + "&zoneCode=" + this.zoneCode;
    window.open(url, "_self");
  }

  /**
   * Load zone code to make connection on the desktop app.
   */

   /**
     * loading
     */
   private get _loadingZoneCode(): boolean {
    return this._loadingZoneCodeSubject.value;
}
private set _loadingZoneCode(value: boolean) {
    if (this._loadingZoneCode !== value) {
        this._loadingZoneCodeSubject.next(value);
    }
}
get loading(): boolean {
    return this._loadingZoneCode;
}
private _loadingZoneCodeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
public loadingZoneCode$: Observable<boolean> = this._loadingZoneCodeSubject.asObservable();

/**
 * Error emitter for retrieving the musicChannelGroups
 * @type {boolean}
 * @private
 */
private __loadingZoneCodeError: boolean = false;
private get _loadingZoneCodeError(): boolean {
    return this.__loadingZoneCodeError;
}
private set _loadingZoneCodeError(value: boolean) {
    if (this.__loadingZoneCodeError !== value) {
        this.__loadingZoneCodeError = value;
        this._loadingZoneCodeErrorSubject.next(this.__loadingZoneCodeError);
    }
}
get loadingZoneCodeError(): boolean{
    return this._loadingZoneCodeError;
}
private _loadingZoneCodeErrorSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);
public loadingZoneCodeError$: Observable<boolean> = this._loadingZoneCodeErrorSubject.asObservable();

/**
 * playToken
 */
private get _zoneCode(): string  {
    return this._zoneCodeSubject.value;
}
private set _zoneCode(value: string ) {
    if (this._zoneCode !== value) {
        this._zoneCodeSubject.next(value);
    }
}
get zoneCode(): string  {
    return this._zoneCode;
}
private _zoneCodeSubject: BehaviorSubject<string > = new BehaviorSubject<string >(null);
public zoneCode$: Observable<string > = this._zoneCodeSubject.asObservable();

private activeZoneChanged$: Subject<void> = new Subject<void>();
public loadZoneCode(): Observable<string>{

    const loadObservable: Observable<DTO_ZoneCode> = this.zoneCodeApiService.loadZoneCode()
    if (loadObservable != null){

      this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadZoneCode', 'Going to load calendarGroups');

      this._loadingZoneCode = true;
      this._loadingZoneCodeError = false;

      loadObservable
        .pipe(
          takeUntil(
            merge(
              this.destroy$,
              this.activeZoneChanged$,
            )
          ),
          finalize(() => {
            this._loadingZoneCode = false;
          })
        )
        .subscribe(
          (data) => {

              if (data.code){
                this._zoneCode = data.code;

                //TODO: start timer based on validForSeconds

                 this.loggerService.debug(this.LOGGER_CLASSNAME, 'loadZoneCode', 'zone code loaded: ' + this.zoneCode);
              }

          },
          (error : unknown) => {
            let errMsg = 'Error';
            if (error instanceof HttpErrorResponse){
              errMsg = (error.message) ? error.message :
              error.status ? `${error.status} - ${error.statusText}` : 'Server error';
            }
            this.loggerService.error(this.LOGGER_CLASSNAME, 'loadZoneCode error', errMsg);
            this._loadingZoneCodeError = true;
          });

    }else{
      this.loggerService.warn(this.LOGGER_CLASSNAME, 'loadCalendarGroups', 'Observable to load calendarGroups was not created');
    }

    return this.zoneCode$;


  }

  private unloadZoneCode(){
    this._zoneCode = null;

    //clear validForSeconds timer
  }
}
