<div class="wrapper">

  <div #playerWrapper (tunResize)="onResize($event)" class="contentWrapper {{(showingPopup$ | async) ? 'blurredContent' : ''}}">
    <tun-main-menu class="menuContainer"></tun-main-menu>
    <tun-main-content class="contentContainer"></tun-main-content>
    <tun-main-player class="playerContainer"></tun-main-player>
  </div>

  <!-- Popup: choose playlist-->
  <div #choosePlaylistPopupWrapper *ngIf="showTrackOverlay$ | async" class="popupWrapper" @slideUpAnimation (click)="onClickOutside($event, choosePlaylistPopupWrapper)">
    <div class="menuContainer noEvents"></div>
    <div #choosePlaylistContentContainer class="contentContainer popupContent" (click)="onClickOutside($event, choosePlaylistContentContainer)">
      <tun-overlay-song-info
        class="select-playlist-overlay"
        [trackInfoOverlayData]="trackInfoOverlayData$ | async"
        (close)="onClosePopup($event)"
      >
      </tun-overlay-song-info>
    </div>
    <div class="playerContainer noEvents"></div>



  </div>
</div>
