<div #playerView (tunResize)="onResize($event)" fxFlex fxLayout="column" class="flexBoxMinimumSizing">

  <!-- This is a test part to try to scale flex components below the needed width for it's children. If you use this, also uncomment the scss 'testStyle' class -->
  <!--
  <div fxFlex fxLayout="Row">
    <div style="width: 200px"></div>
    <div fxFlex class="testStyle" style="background-color: green; min-width: 0;">

          sddskljsldkfjsldkjflskdjflsksddskljsldkfjsldkjflskdjflsksddskljsldkfjsldkjflskdjflsksddskljsldkfjsldkjflskdjflsksddskljsldkfjsldkjflskdjf


    </div>
    <div fxFlex style="background-color: blue"></div>

  </div>
  -->


  <!-- Test for the icons library, we want icons to scale so they stay inside their containing div -->
  <!--
  <div [style.height.px]="heightForTopBar" fxLayout="column" >

      <div fxFlex style="min-width: 0; min-height: 0">
        <tun-calendar-icon style="width: 100%; height: 100%;"></tun-calendar-icon>
      </div>
  </div>
  -->
  <div fxFlex *ngIf="sizeCalculated" fxLayout="column" class="flexBoxMinimumSizing">

    <tun-top-bar  id="topBar"
        class="flexBoxMinimumSizing appBarBackground blackBottomBorder"
        [style.height.px]="heightForTopBar"
        [heightForTopBar]="heightForTopBar"
        [selectedTunifyColor]="selectTunifyColor">
    </tun-top-bar>

    <!-- Original setup with an ngIf for every screen (orange / stream)-->
    <!--
    <tun-stream-view *ngIf="!showDjScreen" fxFlex class="flexBoxMinimumSizing"></tun-stream-view>
    <tun-orange-view *ngIf="showDjScreen" fxFlex class="flexBoxMinimumSizing" style="background-color: lightsalmon"></tun-orange-view>
    -->

    <!--
      It looks like angular creates and inserts the element before the other one is removed
      For a short period, both components will be in the dom. If we use flex layout, the components will be half screen high. That is why we need to place them on top of each other, not in one flex container.
    -->
    <div fxFlex fxLayout="row" class="flexBoxMinimumSizing">

      <div fxFlex class="flexBoxMinimumSizing">
        <div class="layerContainer">

            <tun-loading-view *ngIf="showLoading" class="layer"></tun-loading-view>
            <tun-stream-view *ngIf="!showLoading && !showDjScreen" class="layer"></tun-stream-view>
            <tun-orange-view *ngIf="!showLoading && showDjScreen" class="layer"></tun-orange-view>

        </div>
      </div>

      <tun-right-stream-panel [style.width.px]="widthForContentPanel"
                              class="flexBoxMinimumSizing">
      </tun-right-stream-panel>

    </div>






    <tun-bottom-bar id="bottomBar" class="flexBoxMinimumSizing appBarBackground blackTopBorder" [style.height.px]="heightForBottomBar-1">
    </tun-bottom-bar>
  </div>


</div>



